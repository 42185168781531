/* eslint-disable */
import { getQuotasPart } from '@vacationtracker/shared/graphql/shared-queries'

export const getDashboardData = /* GraphQL */ `
  query GetDashboardData(
    $id: String!
    $date: String!
  ) {
    getCompany {
      id
      name
      plan
      hourlyLeaveAccounting
    }
    getUser(id: $id) {
      id
      name
      locationId
      teamId
      role
      isAdmin
      status
      startDate
      email
      platform
      imageUrl
      workWeek
      timestamp
      locale
      hourFormat
      approverTo {
        id
      }
      location {
        id
        name
        workWeek
        firstDayOfWeek
        timezone
        leavePolicies {
          leaveType {
            id
            name
            isActive
            deleted
            position
          }
          id
          shortestLeaveInterval
          allowHalfDays
          isActive
          isReasonRequired
          allowLeaveInPast
        }
      }
      team {
        id
        name
        approvers {
          id
        }
      }
      status
    }
    getUsersOffToday(date: $date) {
      id
      name
      imageUrl
      team {
        id
        name
      }
      labels {
        id
        name
        color
      }
      location {
        id
        name
        leavePolicies {
          id
          hideLeaveType
          leaveType {
            name
            color
            isActive
            deleted
          }
        }
      }
      status
      today(date: $date) {
        id
        status
        startDate
        endDate
        workingDays
        isPartDay
        partDayStartHour
        partDayEndHour
        partDay {
          startHour
          startMinute
          endHour
          endMinute
        }
        reason
        leavePolicy {
          hideLeaveType
          leaveType {
            id
            name
            color
            deleted
          }
        }
        approver {
          id
          name
        }
        autoApproved
      }
    }
    getLocationList {
      id
      name
      holidays {
        year
        holidays {
          name
          date
          multiDayId
          isHalfDay
        }
      }
    }
    getTeamListV2 {
      id
      name
    }
    getLabels {
      id
      name
      color
    }
  }
`;

export const getMyProfileData = /* GraphQL */ `
  query GetMyProfileData(
    $id: String!,
    $date: String,
    $pendingLeavesDate: String,
  ) {
    getCompany {
      id
      name
      hourlyLeaveAccounting
    }
    getUser(id: $id) {
      id
      name
      locationId
      teamId
      role
      isAdmin
      status
      startDate
      email
      platform
      imageUrl
      workWeek
      workWeekType
      workWeekInfo
      workHours
      timestamp
      locale
      hourFormat
      location {
        id
        name
        workWeek
        firstDayOfWeek
        resetQuotas
        rolloverNeverExpireDays
        rolloverExpiryMonth
        rolloverExpiryDay
        rolloverExpiryAfterDays
        timezone
        yearStartDay
        leavePolicies {
          id
          allowHalfDays
          isActive
          isReasonRequired
          maxRolloverDays
          hasUnlimitedDays
          accrualType
          allowLeaveInPast
          firstEarningDate
          accrualPeriodStart
          shortestLeaveInterval
          leaveType {
            id
            name
            deleted
            isActive
            position
          }
          allowHalfDays
          isActive
          isReasonRequired
        }
        holidays {
          year
          holidays {
            name
            date
            multiDayId
            isHalfDay
          }
        }
      }
      team {
        id
        name
        approvers {
          id
        }
      }
      leaveDays {
        yearStart
        yearEnd
        workingHours
        leaveTypes {
          leavePolicyId
          leaveTypeId
          leaveTypeName
          isActive
          position
          color
          accrualType
          currentDays
          earnedDays
          earnedDaysWithoutRollover
          defaultDaysPerYear
          seniorityEntitlementDaysForYear
          entitlementByRoleDaysForYear
          senioirtyEntitlementExists
          entitlementByRoleExists
          baseQuota
          quota
          toilDays
          toilDaysLapsed
          capHit
          expiredDays
          hasUnlimitedDays
          rolloverFromPreviousYear
          usedDays
          scheduledDays
          userLeaveTypeOverride
          ignoreRolloverFromPreviousYear
          allowHalfDays
          shortestLeaveInterval
          rolloverExpiryDate
          shouldProrate
          userProratedLeaveTypes
          pauseAccrualsWhenUserIsDeactivated
          ${getQuotasPart()}
        }
      }
      status
      upcomingLeaves(date: $date) {
        id
        status
        user {
          id
        }
        leavePolicy {
          id
          leaveType {
            id
            name
            color
          }
          daysPerYear
          hideLeaveType
        }
        approver {
          id
          name
        }
        autoApproved
        startDate
        endDate
        isPartDay
        partDayStartHour
        partDayEndHour
        partDay {
          startHour
          startMinute
          endHour
          endMinute
        }
        reason
        statusReason
        workingDays
        isEdited
        approvedBySubstituteApprover
      }
      pendingLeaves(date: $pendingLeavesDate) {
        id
        status
        startDate
        endDate
        isPartDay
        partDayStartHour
        partDayEndHour
        partDay {
          startHour
          startMinute
          endHour
          endMinute
        }
        leaveType {
          id
          name
        }
        autoApproved
        reason
        workingDays
        totalThisYear
        totalNextYear
        isEdited
      }
      history(date: $date) {
        id
        status
        user {
          id
        }
        leaveType {
          id
          name
          color
          deleted
        }
        approver {
          id
          name
        }
        autoApproved
        startDate
        endDate
        isPartDay
        partDayStartHour
        partDayEndHour
        partDay {
          startHour
          startMinute
          endHour
          endMinute
        }
        reason
        statusReason
        workingDays
        cancelledBeforeReview
        isEdited
        approvedBySubstituteApprover
      }
      today(date: $date) {
        id
        startDate
        endDate
        isPartDay
        partDayStartHour
        partDayEndHour
        partDay {
          startHour
          startMinute
          endHour
          endMinute
        }
        reason
        workingDays
        approvedBySubstituteApprover
        leaveType {
          id
          name
          color
          deleted
        }
        statusReason
      }
    }
  }
`;

// If you do not have a nextToken, use the "NONE" value, as we need a value for cache eviction
export const getLeavesByDateAndStatus = /* GraphQL */ `
  query GetLeavesByDateAndStatus(
    $dateStart: String!
    $dateEnd: String!
    $status: String!
    $limit: Int!
    $nextToken: String!
  ) {
    getLeaveRequestByDate(
      dateStart: $dateStart
      dateEnd: $dateEnd
      status: $status
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      leaveRequests {
        id
        user {
          id
          name
          imageUrl
          team {
            id
            approvers {
              id
            }
          }
          labels {
            id
            name
            color
          }
          location {
            id
            name
          }
        }
        status
        approver {
          id
          name
        }
        leavePolicy {
          id
          hideLeaveType
          leaveType {
            id
            name
            color
            deleted
          }
        }
        autoApproved
        startDate
        endDate
        isPartDay
        partDayStartHour
        partDayEndHour
        partDay {
          startHour
          startMinute
          endHour
          endMinute
        }
        reason
        workingDays
        calendarDays
        totalThisYear
        totalNextYear
        isEdited
        approvedBySubstituteApprover
      }
    }
  }
`;

export const getPendingForApproverById = /* GraphQL */ `
  query GetPendingForApproverById($approverId: String!) {
    getPendingForApprover(approverId: $approverId) {
      id
      user {
        id
        name
        locationId
        teamId
        role
        startDate
        email
        platform
        imageUrl
        timestamp
        locale
        location {
          id
          name
        }
        team {
          id
          name
          timestamp
        }
        status
        approverTo {
          id
          name
          locationId
          teamId
          role
          startDate
          email
          platform
          imageUrl
          timestamp
          status
        }
      }
      status
      leavePolicy {
        id
        leaveType {
          id
          name
          isActive
          color
          position
          deleted
        }
        locationId
        isActive
        daysPerYear
        maxRolloverDays
        isApprovalRequired
        hasUnlimitedDays
        allowHalfDays
        hideLeaveType
        isReasonRequired
        accrualType
        negativeBallanceAllowed
      }
      startDate
      endDate
      isPartDay
      partDayStartHour
      partDayEndHour
      partDay {
        startHour
        startMinute
        endHour
        endMinute
      }
      reason
      teamId
      locationId
      workingDays
      calendarDays
      totalThisYear
      totalNextYear
      statusReason
      isEdited
    }
  }
`;

export const getUserIdsForApprover = /* GraphQL */ `
  query GetUsersForApprover($id: String!) {
    getUser(id: $id) {
      id
      approverTo {
        id
      }
    }
  }
`;

export const eventNotificationSubscription = /* GraphQL */ `
  subscription GetNotificationEvents ($userId: String!) {
    eventResponses(userId: $userId) {
      companyId
      userId
      correlationId
      code
      message
      originalEvent
      result
    }
  }
`;

export const companyUpdateSubscription = /* GraphQL */ `
  subscription GetCompanyUpdates($companyId: String!) {
    companyUpdates(companyId: $companyId) {
      companyId
      userId
      correlationId
      code
      message
      originalEvent
      result
    }
  }
`
// If you do not have a nextToken, use the "NONE" value, as we need a value for cache eviction
export const getCalendarData = /* GraphQL */ `
  query GetCalendarData(
    $dateStart: String!
    $dateEnd: String!
    $status: String!
    $limit: Int!
    $nextToken: String!
  ) {
    getLeaveRequestByDate(
      dateStart: $dateStart
      dateEnd: $dateEnd
      status: $status
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      leaveRequests {
        id
        user {
          id
          name
          locationId
          teamId
          locale
          labels {
            id
            name
            color
          }
        }
        startDate
        endDate
        isPartDay
        partDayStartHour
        partDayEndHour
        partDay {
          startHour
          startMinute
          endHour
          endMinute
        }
        reason
        teamId
        locationId
        leaveType {
          id
          isActive
          name
          color
          deleted
          position
          leavePolicies {
            locationId
            hideLeaveType
          }
        }
      }
    }
  }
`;

export const getCalendarInfo = /* GraphQL */ `
  query GetCalendarInfo(
    $id: String!
  ) {
    getCompany {
      id
      name
      plan
      hourlyLeaveAccounting
    }
    getUser(id: $id) {
      id
      name
      role
      isAdmin
      workWeek
      locale
      hourFormat
      approverTo {
        id
      }
      location {
        timezone
      }
      status
    }
    getLocationList {
      id
      name
      leavePolicies {
        hideLeaveType
        leaveType {
          id
          name
          isActive
          color
          deleted
          position
        }
      }
      timezone
      holidays {
        year
        holidays {
          name
          date
          multiDayId
          isHalfDay
        }
      }
    }
    getTeamListV2 {
      id
      name
      isDefault
    }
    getLabels {
      id
      name
      color
    }
    getBlackoutPeriodForUser {
      id
      name
      startDate
      endDate
      recurring
      locations
      teams
      labels
    }
  }
`;

export const getOverlappingUsersInfo = /* GraphQL */ `
  query getOverlappingUsersInfo($teamId: String!, $leaveRequest: BasicLeaveRequestInfo) {
    getOverlappingUsersInfo(teamId: $teamId, leaveRequest: $leaveRequest) {
      message
      employees
      calendarDays
      workingDays
      workingHours
      leaveDaysList
      year1Days
      year2Days
      daysOnLeaveEndDates
      userWorkingHours
    }
  }
`

export const getLeaveTypesForUser = /* GraphQL */ `
  query GetLeaveTypesForUser($id: String!) {
    getUser(id: $id) {
      id
      name
      locale
      status
      hourFormat
      workHours
      role
      team {
        id
        name
        approvers {
          id
          name
        }
      }
      leaveDays {
        yearStart
        yearEnd
        workingHours
        leaveTypes {
          leavePolicyId
          leaveTypeId
          leaveTypeName
          isActive
          position
          color
          accrualType
          currentDays
          earnedDays
          earnedDaysWithoutRollover
          defaultDaysPerYear
          expiredDays
          hasUnlimitedDays
          rolloverFromPreviousYear
          usedDays
          seniorityEntitlementDaysForYear
          baseQuota
          toilDays
          toilDaysLapsed
          quota
          senioirtyEntitlementExists
          scheduledDays
          userLeaveTypeOverride
          ignoreRolloverFromPreviousYear
          allowHalfDays
          shortestLeaveInterval
          rolloverExpiryDate
          firstEarningDate
          accrualPeriodStart
          ${getQuotasPart()}
        }
      }
      location {
        id
        timezone
        yearStartDay
        holidays {
          year
          holidays {
            name
            date
            multiDayId
            isHalfDay
          }
        }
        leavePolicies {
          id
          name
          isActive
          allowHalfDays
          shortestLeaveInterval
          isReasonRequired
          allowLeaveInPast
          isApprovalRequired
          maxRolloverDays
          maxRolloverHours
          quotaSetIn
          toil
          toilRequestsAllowedForUsers
          toilShortestInterval
          allowAdvanceAccrualUsage
          leaveType {
            id
            name
            position
            deleted
            isActive
            calendarStatus
          }
        }
      }
    }
  }
`;

export const getUserData = /* GraphQL */ `
  query GetUser($id: String!) {
    getUser(id: $id) {
      companyId
    }
  }
`;

export const getConnectedCalendar = /* GraphQL */ `
  query GetConnectedCalendar($userId: String!) {
    getConnectedGooogleCalendar(userId: $userId) {
      calendarId
      timezone
      type
      accessToken
      refreshToken
    }
    getConnectedOutlookCalendar(userId: $userId) {
      calendarId
      timezone
      type
      accessToken
      refreshToken
    }
  }
`;


export const getUsersForAdminWithPagination = /* GraphQL */ `
  query GetUsersForAdminWithPagination(
    $limit: Int = 500
    $nextToken: String
  ) {
    getActiveUsersWithPagination(
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      limit
      users {
        id
        name
      }
    }
  }
`

export const getSuggestedSubstituteApprovers = /* GraphQL */ `
  query getSuggestedSubstituteApprovers($limit: Int = 100, $nextToken: String, $upcomingLeavesDate: String) {
    getActiveUsersWithPagination(limit: $limit, nextToken: $nextToken) {
      nextToken
      limit
      users {
        id
        name
        role
        teamId
        upcomingLeaves(date: $upcomingLeavesDate) {
          id
          status
          startDate
          endDate
          isPartDay
          partDayStartHour
          partDayEndHour
          partDay {
            startHour
            startMinute
            endHour
            endMinute
          }
          leaveType {
            id
            name
          }
        }
      }
    }
  }
`

export const getTeamsForApprover = /* GraphQL */ `
  query getTeamsForApprover($id: String!, $date: String) {
    getUser(id: $id) {
      approverToTeams {
        id
        name
        approvers {
          id
          name
          approverLevels
          upcomingLeaves(date: $date) {
            id
            status
            user {
              id
            }
            leavePolicyId
            approver {
              id
              name
            }
            leaveType {
              name
            }
            autoApproved
            startDate
            endDate
            isPartDay
            partDayStartHour
            partDayEndHour
            partDay {
              startHour
              startMinute
              endHour
              endMinute
            }
            reason
            statusReason
            workingDays
            isEdited
          }
        }
      }
    }
  }
`

export const getAutomations = /* GraphQL */ `
  query getAutomations {
    getAutomations {
      id
      name
      leaveTypeIds
      leaveTypeId
      automationType
      startDate
      calculateFrom
      endDate
      recurring
      periods {
        days
        years
      }
      isActive
      reason
      locations
      teams
      labels
      labelId
      days
      createdAt
      awardSeniorityEntitlementDaysOn
      prorate
      error
      errorCode
      visibilityLevel
    }
  }
`

export const getCompanyPlan = /* GraphQL */ `
  query GetCompanyPlan {
    getCompany {
      id
      name
      plan
    }
  }
`
export const getLeaveRequestData = /* GraphQL */ `
  query GetLeaveRequestData($leaveRequestId: String!) {
    getLeaveRequest(id: $leaveRequestId) {
      id
      userId
      user {
        locationId
      }
      status
      leavePolicyId
      calendarStatus
      leaveType {
        id
        name
        calendarStatus
        leavePolicies {
          shortestLeaveInterval
          isReasonRequired
          locationId
        }
      }
      autoApproved
      startDate
      endDate
      isPartDay
      partDayStartHour
      partDayEndHour
      partDay {
        startHour
        startMinute
        endHour
        endMinute
      }
      reason
      workingDays
      daysList {
        day
        leaveHours
        workingHoursInDay
      }
      substituteApproverId
    }
  }
`