import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { difference, isEqual, isEmpty, uniqBy } from 'lodash'
import FullCalendar from '@fullcalendar/react'
import { DatesSetArg } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import { useManualQuery } from 'graphql-hooks'
import allLocales from '@fullcalendar/core/locales-all'
import { App, Typography } from 'antd'
import { format, endOfMonth, startOfMonth, addDays, startOfDay, endOfDay, setDate, subMonths, addMonths } from 'date-fns'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isSameOrBefore)
dayjs.extend(isBetween)
import { zonedTimeToUtc } from 'date-fns-tz'
import ReactTooltip from 'react-tooltip'
import * as Sentry from '@sentry/react'

import { MicrosoftTeams } from '../../services/auth/microsoft/microsoft'
import { getFeatureFlags } from '../../services/api/vacationtracker'
import { getCalendarData, getCalendarInfo } from '../../graphql/custom-queries'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setFeatureFlags, selectFeatureFlagsSlice } from '../../store/FeatureFlagsSlice'
import { selectLocaleSlice, setLocale } from '../../store/LocaleSlice'
import { selectUserIdSlice } from '../../store/UserIdSlice'
import { invertHexColor } from '@vacationtracker/shared/components/utils/invert-color-wrapper'
import { filterData } from '@vacationtracker/shared/components/utils/filter-date'
import { getPrefferedLanguage } from '../../util/get-preffered-language'
import getDateInUserTimezone from '@vacationtracker/shared/functions/get-date-in-user-timezone'
import { formatTimeString } from '@vacationtracker/shared/functions/get-days-or-hours'
import { convertHourFormats } from '@vacationtracker/shared/functions/convert-between-hour-formats'

import IntlMessages from '../../util/IntlMessages'
import CircularProgress from '../../components/circular-progress'
import FilterAdvanced from '@vacationtracker/shared/components/filter-advanced'
import LocationLeaveTypesTag, { ILeaveTypesShort } from '@vacationtracker/shared/components/location-leave-type-tag'

import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import {
  shortDayNames,
  allDaysAsNumbers
} from '@vacationtracker/shared/types/calendar'
import { IGetUserIdsForApproverTo } from '../DashboardPage/types'
import {
  ICalendarEvent,
  IGetTeamsShort,
  IGetLocationListForCalendar,
  IGetLabelsShort,
  IHolidaysData,
  IGetCalendarInfoData,
  IUserShort,
  ICompanyShort,
  ICalendarLeaves,
  ICalendarLeavesResponse
} from './types'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { IGetBlackoutPeriodForUser } from '../../../../frontend/src/types/custom-queries'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'

const { Paragraph } = Typography


const computeNotWorkingDays = (workWeekDays: number[]): number[] => {
  if (workWeekDays?.length === 0) {
    return []
  }
  return difference(allDaysAsNumbers, workWeekDays)
}

const getLoggedInUserNotWorkWeekSelectors = (workWeekDays: string[]): string => {
  if (workWeekDays?.length === 0) {
    return ''
  }
  return workWeekDays.map(day => `user-not-work-day-${day.toLowerCase()}`).join(' ')
}

const getHolidayName = (holidays, current) => {
  const holidaysWithTheSameName = holidays.filter(holiday => holiday.name === current.name && holiday.date === current.date)
  if (holidaysWithTheSameName.length > 1) {
    return `${current.name} (${holidaysWithTheSameName.map(h => h.locationName).join(', ')})`
  }
  return `${current.name} (${current.locationName})`
}

const leftPad = (n: number) => (`0${n}`).slice(-2)


const CalendarTabComponent: React.FC = () => {
  const msAuth = new MicrosoftTeams()
  const now = new Date()
  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()
  const calendarRef = useRef<FullCalendar>(null)
  const { featureFlags } = useAppSelector(selectFeatureFlagsSlice)
  const { userId } = useAppSelector(selectUserIdSlice)
  const { locale } = useAppSelector(selectLocaleSlice)
  const { notification } = App.useApp()
  const [fetchCalendarInfoQuery] = useManualQuery(getCalendarInfo)
  const [fetchCalendarDataQuery] = useManualQuery<ICalendarLeavesResponse>(getCalendarData)

  const [startWeek, setStartOfWeek] = useState(() => {
    if (localStorage.getItem('calendarStartOfWeek')) {
      return localStorage.getItem('calendarStartOfWeek')
    } else {
      return 'mon-fri'
    }
  })
  const [calendarView, setCalendarView] = useState(() => {
    if (localStorage.getItem('calendarView')) {
      return localStorage.getItem('calendarView')
    } else {
      return 'dayGridMonth'
    }
  })
  const [authUser, setAuthUser] = useState<IUserShort | null>(null)
  const [authCompany, setAuthCompany] = useState<ICompanyShort | null>(null)
  const [approverToUsers, setApproverToUsers] = useState<IGetUserIdsForApproverTo[]>([])
  const [leaves, setLeaves] = useState<ICalendarEvent[]>([])
  const [calendarEvents, setCalendarEvent] = useState<ICalendarEvent[]>([])
  const [calendarHolidaysEvents, setCalendarHolidaysEvent] = useState<ICalendarEvent[]>([])
  const [teams, setTeams] = useState<IGetTeamsShort[]>([])
  const [leaveTypes, setLeaveTypes] = useState<ILeaveTypesShort[]>([])
  const [isCalendarDataLoading, setCalendarDataLoading] = useState(true)
  const [isCalendarInfoLoading, setCalendarInfoLoading] = useState(true)
  const [loggedInUserNotWorkingDays, setLoggedInUserNotWorkingDays] = useState<string[]>([])
  const [allLocations, setAllLocations] = useState<IGetLocationListForCalendar[]>([])
  const [locations, setLocations] = useState<IGetLocationListForCalendar[]>([])
  const [labels, setLabels] = useState<IGetLabelsShort[]>([])
  const [filters, setFilters] = useState({
    locationIds: [],
    teamIds: [],
    labelIds: [],
  })
  const [blackoutPeirodEvents, setBlackoutPeirodEvents] = useState<ICalendarEvent[]>([])
  const [recurringBlackoutPeirods, setRecurringBlackoutPeirods] = useState<IGetBlackoutPeriodForUser[]>([])
  const [recurringBlackoutPeirodsEvents, setRecurringBlackoutPeirodsEvents] = useState<ICalendarEvent[]>([])

  useEffect(() => {
    changeView(calendarView)
  }, [calendarView])

  useEffect(() => {
    handleLeaves(filters, leaves)
    handleHolidays(filters.locationIds)
  }, [filters])

  useEffect(() => {
    if (userId) {
      fetchCalendarInfo(userId)
    }
  }, [userId])

  const changeView = view => {
    const API = getApi()

    API && API.changeView(view)
  }

  const getApi = () => {
    const { current: calendarDom } = calendarRef

    return calendarDom ? calendarDom.getApi() : null
  }

  const fetchCalendarInfo = async (id) => {
    const response = await fetchCalendarInfoQuery({ variables: { id }}) as IGetCalendarInfoData
    const user = response.data.getUser
    const responseFeatureFlags = await getFeatureFlags(user.role, 'CALENDAR')
    const msContext = await msAuth.getContext()
    const userLanguagePreferance = getPrefferedLanguage(user.locale, msContext.app.locale.toLowerCase())
    dispatch(setLocale(userLanguagePreferance))
    dispatch(setFeatureFlags(responseFeatureFlags.enabledFeatures))

    setAuthCompany(response.data.getCompany as ICompanyShort)
    setAuthUser(response.data.getUser as IUserShort)

    setLoggedInUserNotWorkingDays(
      computeNotWorkingDays(response.data.getUser.workWeek)
        .map(dayNumber => formatMessage({ id: shortDayNames[dayNumber] }))
    )
    setApproverToUsers(response.data.getUser.approverTo)

    const locationsWithHolidays = response.data.getLocationList.map(location => {
      return {
        ...location,
        holidays: location.holidays.map(holidaysForYear => {
          return {
            year: holidaysForYear.year,
            holidays: holidaysForYear.holidays.map(holiday => {
              return {
                ...holiday,
                locationName: location.name,
              }
            }),
          }
        }),
      }
    })

    setRecurringBlackoutPeirods(
      response.data.getBlackoutPeriodForUser
        .filter(blackoutPeriod => blackoutPeriod.recurring)
        .map(blackoutPeriod => {
          return {
            ...blackoutPeriod,
            startDate: dayjs(blackoutPeriod.startDate).add(1, 'year').format('YYYY-MM-DD'),
            endDate: dayjs(blackoutPeriod.endDate).add(1, 'year').format('YYYY-MM-DD'),
          }
        })
    )
    setBlackoutPeirodEvents(handleBlackoutPeriodEvents(response.data.getBlackoutPeriodForUser))

    setLocations(response.data.getLocationList)
    setTeams(response.data.getTeamListV2)
    setAllLocations(locationsWithHolidays)
    handleHolidays([], locationsWithHolidays)
    setLabels(response.data.getLabels)
    setCalendarInfoLoading(false)
  }

  const fetchData = async (dateStart: Date, dateEnd: Date, limit = 700, nextToken = 'NONE', leaves: ICalendarEvent[] = []): Promise<ICalendarEvent[]> => {
    try {
      const response = await fetchCalendarDataQuery({variables: {
        dateStart: format(startOfMonth(subMonths(setDate(dateStart, 15), 1)), 'yyyy-MM-dd'),
        dateEnd: format(endOfMonth(addMonths(setDate(dateStart, 15), 1)), 'yyyy-MM-dd'),
        status: 'APPROVED',
        limit,
        nextToken,
      }})

      if (!response || !response.data || !response.data.getLeaveRequestByDate) {
        throw new Error(JSON.stringify(response))
      }

      const newLeaves = leavesDataWrapper([
        ...response.data.getLeaveRequestByDate.leaveRequests,
      ])

      if (response.data.getLeaveRequestByDate.nextToken) {
        return await fetchData(dateStart, dateEnd, limit, response.data.getLeaveRequestByDate.nextToken, [
          ...leaves,
          ...newLeaves,
        ])
      }

      return [
        ...leaves,
        ...newLeaves,
      ]
    } catch (error) {
      Sentry.captureException(error)

      notification.error({
        key: 'calendar-loading-error',
        message: formatMessage({ id: 'error.leaveRequestsLoadingError.title' }),
        description: (<>
          <Paragraph>{
            formatMessage({ id: 'error.leaveRequestsLoadingError.description' }, {
              link: (...chunks) => <a href="https://vacationtracker.crisp.help/en/" target="_blank" rel="noreferrer">{chunks}</a>,
            })
          }</Paragraph>
        </>),
        duration: 0,
      })

      return []
    }
  }

  const fetchDataAndSetState = async (dateStart: Date, dateEnd: Date, callHandleLeaves = false) => {
    const leaves = await fetchData(dateStart, dateEnd)

    setLeaves(leaves)
    setRecurringBlackoutPeirodsEvents(handleRecurringBlackoutPeirods(dateStart, dateEnd))
    callHandleLeaves && handleLeaves(filters, leaves)
    setCalendarDataLoading(false)
    setCalendarInfoLoading(false)
  }

  const holidayDataWrapper = (data: IHolidaysData[]) => {
    const holidaysArray: IHolidaysData[] = []
    data.forEach((single => {
      if (single.multiDayId) {
        if (holidaysArray.filter(holiday => single.multiDayId === holiday.multiDayId).length === 0) {
          const filtered = data
            .filter(holiday => holiday.multiDayId === single.multiDayId)
            .sort((a, b) => dayjs(a.date).format('YYYY-MM-DD') < dayjs(b.date).format('YYYY-MM-DD') ? -1 : 1)
          holidaysArray.push({ ...single, endDate: filtered[filtered.length - 1].date })
        }
      } else {
        delete single.multiDayId
        holidaysArray.push(single)
      }
    }))
    const holidaysEvent: ICalendarEvent[] = holidaysArray
      .filter((holiday, index, holidaysArray) => {
        // do not show holidays with the same name
        return index === holidaysArray.findIndex((h) => {
          return h.name === holiday.name && h.date === holiday.date
        })
      }
      )
      .map((holiday: IHolidaysData) => {
        const calendarViewClass = holiday.isHalfDay && calendarView !== 'listMonth'  ? 'striped' : 'regular'
        const isHalfDayHolidayClass = holiday.isHalfDay ? 'half-day-holiday' : 'full-day-holiday'
        const extraClass = `${calendarViewClass} ${isHalfDayHolidayClass}`
        const holidayName = getHolidayName(holidaysArray, holiday)

        return {
          userId: '',
          // display one holiday if it is the same for diff locatiions
          title: calendarView === 'listMonth' ?
            `[${formatMessage({ id: 'app.holidays' })}${holiday.isHalfDay ? formatMessage({ id: 'app.halfDayInParenthesis' }) : ''}] ${holidayName}` : holidayName,
          description: '',
          orginalTitle: holidayName,
          time: '',
          start: dayjs(holiday.date).format('YYYY-MM-DD'),
          end: dayjs(holiday.endDate || holiday.date).add(1, 'day').format('YYYY-MM-DD'),
          allDay: !holiday.isHalfDay,
          isHalfDays: holiday.isHalfDay as boolean,
          className: '',
          backgroundColor: '#7266ba',
          borderColor: '#7266ba',
          leaveTypeName: 'Holiday',
          teamId: '',
          typeDisabled: false,
          classNames: [extraClass],
          textColor: '#fff',
          locationId: '',
          typeDeleted: false,
          isHoliday: true,
        }
      })

    return holidaysEvent
  }

  const handleHolidays = (locationIds: string[], locationsHolidays?: IGetLocationListForCalendar[]) => {
    let locations: IGetLocationListForCalendar[] = allLocations
    if (!isEmpty(locationsHolidays) && locationsHolidays) {
      locations = locationsHolidays
    }

    let holidaysData: IHolidaysData[] = []
    if(!isEmpty(locationIds)) {
      locations = allLocations.filter(location => locationIds.includes(location.id))
    }

    locations.forEach(location => {
      if (location.holidays.length > 0) {
        location.holidays.forEach(holiday => {
          holidaysData = holidaysData.concat((holiday.holidays as IHolidaysData[]))
        })
      }
    })

    setCalendarHolidaysEvent(holidayDataWrapper(holidaysData))
  }

  const getStartAndEndTimes = (leave): {
    start: string
    end: string
  } => {
    if (leave.start && leave.end) {
      const { start, end } = leave
      return {
        start,
        end,
      }
    } else {
      const requestorTimeZone = locations.find(loc => loc.id === leave.locationId)?.timezone
      const formatStr = 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX'
      if (leave?.partDay) {
        const leaveStartTime = formatTimeString({hour: leave?.partDay?.startHour, minute: leave?.partDay?.startMinute as number, showZeroPadding: true})
        const leaveEndTime = formatTimeString({hour: leave?.partDay?.endHour, minute: leave?.partDay?.endMinute as number, showZeroPadding: true})
        return {
          start: format(zonedTimeToUtc(`${leave.startDate}T${leaveStartTime}:00.000`, requestorTimeZone as string), formatStr),
          end: format(zonedTimeToUtc(`${leave.endDate}T${leaveEndTime}:00.000`, requestorTimeZone as string), formatStr),
        }
      } else {
        return {
          start: format(zonedTimeToUtc(`${leave.startDate}T${leftPad(leave.partDayStartHour)}:00:00.000`, requestorTimeZone as string), formatStr),
          end: format(zonedTimeToUtc(`${leave.startDate}T${leftPad(leave.partDayEndHour)}:00:00.000`, requestorTimeZone as string), formatStr),
        }
      }
    }
  }

  const handleBlackoutPeriodEvents = (blackoutPeirodEvents: IGetBlackoutPeriodForUser[]): ICalendarEvent[] => {
    const events: ICalendarEvent[] = []

    blackoutPeirodEvents.forEach(blackoutPeriod => {
      events.push({
        userId: '',
        title: `[${formatMessage({ id: 'automations.BLACKOUT_PERIOD' })}] ${blackoutPeriod.name}`,
        description: '',
        orginalTitle: blackoutPeriod.name,
        time: '',
        start: dayjs(blackoutPeriod.startDate).format('YYYY-MM-DD'),
        end: dayjs(blackoutPeriod.endDate).add(1, 'day').format('YYYY-MM-DD'),
        allDay: true,
        isHalfDays: false,
        className: '',
        backgroundColor: '#545454',
        borderColor: '#545454',
        leaveTypeName: 'BlackoutPeriod',
        teamId: '',
        typeDisabled: false,
        textColor: '#fff',
        locationId: '',
        classNames: [],
        typeDeleted: false,
        isHoliday: false,
        teams: blackoutPeriod.teams,
        locations: blackoutPeriod.locations,
        labels: blackoutPeriod.labels,
      } as ICalendarEvent)
    })

    return(events)
  }

  const handleRecurringBlackoutPeirods = (dateStart: Date, dateEnd: Date) => {
    const eventsRaw: IGetBlackoutPeriodForUser[] = []

    const isCalendarPeriodInSameYear = dayjs(dateStart).year() === dayjs(dateEnd).year()

    recurringBlackoutPeirods.forEach(blackoutPeriod => {
      // Skip if blackout period is not created in the current calendar showing dates
      if (dayjs(dateEnd).isBefore(blackoutPeriod.startDate)) {
        return
      }

      const isBlackoutPeriodInSameYear = dayjs(blackoutPeriod.startDate).year() === dayjs(blackoutPeriod.endDate).year()
      let blackoutStartDate = blackoutPeriod.startDate
      let blackoutEndDate = blackoutPeriod.endDate

      if ((isBlackoutPeriodInSameYear && isCalendarPeriodInSameYear) || (!isBlackoutPeriodInSameYear && !isCalendarPeriodInSameYear)) {
        blackoutStartDate = dayjs(blackoutPeriod.startDate).set('year', dayjs(dateStart).year()).format('YYYY-MM-DD')
        blackoutEndDate = dayjs(blackoutPeriod.endDate).set('year', dayjs(dateEnd).year()).format('YYYY-MM-DD')
      } else if (isBlackoutPeriodInSameYear && !isCalendarPeriodInSameYear) {

        if (dayjs(dateStart).isBetween(
          dayjs(blackoutPeriod.startDate).set('year', dayjs(dateStart).year()).format('YYYY-MM-DD'),
          dayjs(blackoutPeriod.endDate).set('year', dayjs(dateStart).year()).format('YYYY-MM-DD'),
          'day',
          '[]'
        )) {
          blackoutStartDate = dayjs(blackoutPeriod.startDate).set('year', dayjs(dateStart).year()).format('YYYY-MM-DD')
          blackoutEndDate = dayjs(blackoutPeriod.endDate).set('year', dayjs(dateStart).year()).format('YYYY-MM-DD')
        } else {
          blackoutStartDate = dayjs(blackoutPeriod.startDate).set('year', dayjs(dateEnd).year()).format('YYYY-MM-DD')
          blackoutEndDate = dayjs(blackoutPeriod.endDate).set('year', dayjs(dateEnd).year()).format('YYYY-MM-DD')
        }
      } else if (!isBlackoutPeriodInSameYear && isCalendarPeriodInSameYear) {
        blackoutStartDate = dayjs(blackoutPeriod.startDate).set('year', dayjs(dateStart).year()).format('YYYY-MM-DD')
        blackoutEndDate = dayjs(blackoutPeriod.endDate).set('year', dayjs(dateEnd).year() + 1).format('YYYY-MM-DD')
      }

      if (
        (dayjs(dateStart).isSameOrBefore(blackoutStartDate) && dayjs(blackoutStartDate).isSameOrBefore(dateEnd)) ||
        (dayjs(blackoutStartDate).isSameOrBefore(dateStart) && dayjs(blackoutStartDate).isSameOrBefore(dateEnd)) ||
        (dayjs(dateStart).isSameOrBefore(blackoutStartDate) && dayjs(dateEnd).isSameOrBefore(blackoutStartDate)) ||
        (dayjs(blackoutStartDate).isSameOrBefore(dateStart) && dayjs(dateEnd).isSameOrBefore(blackoutStartDate))
      ) {

        eventsRaw.push({
          ...blackoutPeriod,
          startDate: dayjs(blackoutStartDate).format('YYYY-MM-DD'),
          endDate: dayjs(blackoutEndDate).add(1, 'day').format('YYYY-MM-DD'),
        })
      }
    })

    return handleBlackoutPeriodEvents(eventsRaw)
  }

  const handleSetLeaveTypesAsLabels = (leavesData: ICalendarEvent[]) => {
    const leaveTypesToShowAsLabels: ILeaveTypesShort[] = []
    leavesData
      .map((leave: ICalendarEvent) => {
        leaveTypesToShowAsLabels.push({
          position: leave.leaveTypePosition as number,
          name: leave.leaveTypeName,
          color: leave.borderColor,
          hideLeaveType: leave.hideLeaveType as boolean,
          id: leave.leaveTypeId,
        })
      })
    setLeaveTypes(uniqBy(leaveTypesToShowAsLabels, 'name'))
  }

  const handleLeaves = (filters, orginalLeaves?: ICalendarEvent[]) => {
    let leavesData: ICalendarEvent[] = leaves
    if (orginalLeaves) {
      leavesData = orginalLeaves
    }

    if (isEmpty(filters.teamIds) && isEmpty(filters.locationIds) && isEmpty(filters.labelIds)) {
      handleSetLeaveTypesAsLabels(leavesData)
      setCalendarEvent(leavesData)
      return
    }

    const filteredLeaves: ICalendarEvent[] = filterData(filters, leavesData)
    handleSetLeaveTypesAsLabels(filteredLeaves)
    setCalendarEvent(filteredLeaves)
  }


  const getPartDayLeavePeriod = (leave, hourFormat) => {
    let leaveStartTime = ''
    let leaveEndTime = ''
    if (leave.isPartDay) {
      const { value: startHourValue, minute: startMinute, amOrPm: amOrPmStart } = convertHourFormats(
        hourFormat as HourFormatEnum,
        leave.partDay ? leave.partDay.startHour : leave.partDayStartHour as number,
        leave.partDay ? leave.partDay.startMinute as number : 0
      )
      const { value: endHourValue, minute: endMinute, amOrPm: amOrPmEnd } = convertHourFormats(
        hourFormat as HourFormatEnum,
        leave.partDay ? leave.partDay.endHour : leave.partDayEndHour as number,
        leave.partDay ? leave.partDay.endMinute as number : 0
      )
      leaveStartTime = formatTimeString({hour: startHourValue, minute: startMinute, showZeroPadding: false, hourFormat, amOrPm: amOrPmStart})
      leaveEndTime = formatTimeString({hour: endHourValue, minute: endMinute, showZeroPadding: false, hourFormat, amOrPm: amOrPmEnd})
      return `${leaveStartTime} - ${leaveEndTime}`
    } else {
      return ''
    }
  }

  const leavesDataWrapper = (data: ICalendarLeaves[]) => {
    const events: ICalendarEvent[] = []

    data.filter(leave => leave.leaveType && leave.leaveType.isActive && !leave.leaveType.deleted)
      .forEach(leave => {
        const extraClass = leave.isPartDay && calendarView !== 'listMonth'  ? 'striped' : 'regular'
        const colorSuffix = leave.leaveType.isActive ? 'FF' : '80'
        const isHiddenLeaveType = leave.leaveType.leavePolicies.find(lp => lp.locationId === leave.locationId)?.hideLeaveType ?? false
        const isAdminOrUsersApprover = authUser?.role === 'Admin' || (authUser?.role === 'Approver' && approverToUsers.find(user => user.id === leave.user.id))
        const hideThisLeaveType = isHiddenLeaveType && !isAdminOrUsersApprover
        const leaveTypeName = hideThisLeaveType ? 'Hidden Leave Type' : leave.leaveType.name
        const calendarEvent: ICalendarEvent = {
          userId: leave.user.id,
          leaveRequestId: leave.id,
          title: calendarView === 'listMonth' ? `[${leaveTypeName}] ${leave.user.name}` : leave.user.name,
          orginalTitle: leave.user.name,
          description: isAdminOrUsersApprover && leave.reason ? leave.reason : '',
          time: getPartDayLeavePeriod(leave, authUser?.hourFormat),
          start: format(getDateInUserTimezone(leave.startDate), 'yyyy-MM-dd'),
          end: format(addDays(getDateInUserTimezone(leave.endDate), 1), 'yyyy-MM-dd'),
          allDay: !leave.isPartDay,
          isHalfDays: leave.isPartDay,
          // last pair in HEX color is for Alpha
          backgroundColor: hideThisLeaveType ? '#777777' : leave.leaveType.color + colorSuffix,
          borderColor: hideThisLeaveType ? '#777777' : leave.leaveType.color,
          textColor: invertHexColor(hideThisLeaveType ? '#000000' : leave.leaveType.color, true),
          leaveTypeName,
          teamId: leave.user.teamId,
          typeDisabled: !leave.leaveType.isActive,
          locationId: leave.user.locationId,
          typeDeleted: leave.leaveType.deleted,
          labelIds: leave.user.labels && leave.user.labels.map(label => label.id),
          classNames: [extraClass],
          isHoliday: false,
          leaveTypePosition: hideThisLeaveType ? 999 : leave.leaveType.position,
          hideLeaveType: hideThisLeaveType,
          leaveTypeId: leave.leaveType.id,
        }

        if (leave.isPartDay) {
          const partDayEvent: ICalendarEvent = {
            ...calendarEvent,
            ...getStartAndEndTimes(leave),
          }
          events.push(partDayEvent)
        } else {
          events.push(calendarEvent)
        }
      })

    return events
  }

  const changeStartOfWeek = (week: string) => {
    localStorage.setItem('calendarStartOfWeek', week)
    setStartOfWeek(week)
  }

  const changeClanedarView = (view: string) => {
    setCalendarEvent(prevState => {
      return prevState.map(leaveCalEvent => {
        let calEvent = {
          ...leaveCalEvent,
          title: view === 'listMonth' ? `[${leaveCalEvent.leaveTypeName}] ${leaveCalEvent.orginalTitle}` : leaveCalEvent.orginalTitle || '',
        }

        if (leaveCalEvent.isHalfDays) {
          const extraClass = view !== 'listMonth' ? 'striped' : 'regular'
          calEvent = {
            ...calEvent,
            classNames: [extraClass],
            ...getStartAndEndTimes(leaveCalEvent),
          }
        }

        return calEvent
      })
    })
    setCalendarHolidaysEvent(prevState => {
      return prevState.map(holiday => {
        const calendarViewClass = holiday.isHalfDays && view !== 'listMonth'  ? 'striped' : 'regular'
        const isHalfDayHolidayClass = holiday.isHalfDays ? 'half-day-holiday' : 'full-day-holiday'
        const extraClass = `${calendarViewClass} ${isHalfDayHolidayClass}`
        const title = view === 'listMonth' ?
          `[${formatMessage({ id: 'app.holidays' })}${holiday.isHalfDays ? formatMessage({ id: 'app.halfDayInParenthesis' }) : ''}] ${holiday.orginalTitle}` :
          holiday.orginalTitle

        return {
          ...holiday,
          className: isHalfDayHolidayClass,
          classNames: [extraClass],
          title: title as string,
        }
      })
    })
    localStorage.setItem('calendarView', view)
    setCalendarView(view)
  }


  const handleEventPositioned = (info) => {
    if (authUser?.role === 'Admin' || authUser?.role === 'Approver') {
      const tooltipText = `<p>${formatMessage({ id: 'app.reason' })}:
      ${info.event._def.extendedProps.description} ${info.event._def.extendedProps.typeDisabled ? '</br> This leave type has been disabled' : ''}</p>`
      info.el.setAttribute('data-tip', info.event._def.extendedProps.description ? tooltipText : '')
    }
    if (info?.event?.extendedProps?.leaveTypeName === 'Holiday') {
      // Hide tooltip if calendarView list
      const tooltipText = calendarView === 'dayGridMonth' ? info.event.extendedProps?.orginalTitle : ''
      info.el.setAttribute('data-tip', info.event.extendedProps?.orginalTitle ? tooltipText : '')
    }
    if (info?.event?.extendedProps?.leaveTypeName === 'BlackoutPeriod') {
      const extendedProps = info?.event?.extendedProps
      let extraInfo = '<br/>'
      if (extendedProps.locations.length > 0) {
        const locationsNames = locations.filter(location => extendedProps.locations.includes(location.id)).map(location => location.name)
        extraInfo += `${formatMessage({ id: 'app.locations' })}: ${locationsNames.join(', ')}<br/>`
      }
      if (extendedProps.teams.length > 0) {
        const teamNames = teams.filter(team => extendedProps.teams.includes(team.id)).map(team => team.name)
        extraInfo += `${formatMessage({ id: 'app.departments' })}: ${teamNames.join(', ')}<br/>`
      }
      if (extendedProps.labels.length > 0) {
        const labelsName = labels.filter(label => extendedProps.labels.includes(label.id)).map(label => label.name)
        extraInfo += `${formatMessage({ id: 'app.departments' })}: ${labelsName.join(', ')}<br/>`
      }
      if (extendedProps.locations.length === 0 && extendedProps.teams.length === 0 && extendedProps.labels.length === 0) {
        extraInfo += `${formatMessage({ id: 'calendar.forAllUsers' })}<br/>`
      }
      // Hide tooltip if calendarView list
      const tooltipText = calendarView === 'dayGridMonth' ? `${info.event.extendedProps?.orginalTitle}\n${extraInfo}` : ''
      info.el.setAttribute('data-tip', info.event.extendedProps?.orginalTitle ? tooltipText : '')
    }
    if (info?.event?.extendedProps?.isHalfDays && calendarView === 'listMonth') {
      // Show tooltip if calendarView is list and is part day leave
      const tooltipText = `<p>${formatMessage({id: 'calendar.timezone.info'})}</p>`
      info.el.firstChild.setAttribute('data-tip', tooltipText)
      if (authUser?.role === 'Admin' || authUser?.role === 'Approver') {
        const tooltipText = `<p>${formatMessage({ id: 'app.reason' })}:
        ${info.event._def.extendedProps.description} ${info.event._def.extendedProps.typeDisabled ? '</br> This leave type has been disabled' : ''}</p>`
        info.el.lastChild.setAttribute('data-tip', info.event._def.extendedProps.description ? tooltipText : '')
      }
    }
    ReactTooltip.rebuild()
  }

  const filterChanged = (data) => {
    if(!isEqual(data, filters)) {
      setFilters(data)
    }
  }

  const [startDate, setStartDate] = useState(startOfMonth(now))
  const [endDate, setEndDate] = useState(endOfMonth(now))

  useEffect(() => {
    if (authUser) {
      fetchDataAndSetState(startDate, endDate, true)
    }
  }, [startDate, endDate, authUser])

  const onCalendarSelectionUpdate = (dateInfo: DatesSetArg) => {
    const newStartDate = startOfDay(dateInfo.view.currentStart)
    const newEndDate = endOfDay(dateInfo.view.currentStart)

    if (
      format(startDate, 'yyyy-MM-dd') !== format(newStartDate, 'yyyy-MM-dd') ||
      format(endDate, 'yyyy-MM-dd') !== format(newEndDate, 'yyyy-MM-dd')
    ) {
      setStartDate(newStartDate)
      setEndDate(newEndDate)
    }
  }

  return (
    <div className="main-content-body">
      {isCalendarInfoLoading ?
        <CircularProgress /> :
        <div className="calendar-header">
          <div className="leave-types-tags">
            <LocationLeaveTypesTag leaveTypes={leaveTypes} isBlackoutPeriodExist={Boolean(blackoutPeirodEvents.length === 1)} />
            <p className="m-0"><IntlMessages id="calendar.halfdayTags" /></p>
          </div>
          <div className="filters">
            <div>
              <FilterAdvanced
                page='msTeamsCalendar'
                data={{
                  Locations: locations,
                  Departments: teams,
                  Labels: labels,
                }}
                onChangeFilter={filterChanged}
                showLabels={featureFlags.includes(FeatureFlagEnum.labels) || authCompany?.plan === SubscriptionPlanEnum.complete}
              />
            </div>
          </div>
        </div>
      }
      {isCalendarDataLoading ?
        <CircularProgress /> :
        <div className={`calendar-body start-week-${startWeek} calendar-view-${calendarView} ${getLoggedInUserNotWorkWeekSelectors(loggedInUserNotWorkingDays)}`} >
          <FullCalendar
            locales={allLocales}
            locale={locale.locale}
            allDayText={formatMessage({id: 'app.allDay'})}
            ref={calendarRef}
            timeZone={authUser?.location.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone}
            plugins={[ momentTimezonePlugin, dayGridPlugin, listPlugin ]}
            contentHeight={calendarView === 'listMonth' ? 0 : 840}
            displayEventTime={calendarView === 'listMonth'}
            initialView={localStorage.getItem('calendarView') && localStorage.getItem('calendarView') === 'listMonth' ? 'listMonth' : 'dayGridMonth'}
            dayMaxEventRows={true}
            eventDisplay={'block'}
            firstDay={startWeek === 'mon-fri' ? 1 : 0}
            datesSet={onCalendarSelectionUpdate}
            initialDate={now}
            eventOrder={'-isHoliday,start,-duration,allDay,title'}
            eventOrderStrict={false}
            customButtons={{
              changeWeekMonFri: {
                text: formatMessage({ id: 'calendar.week.monFri' }),
                click: () => { changeStartOfWeek('mon-fri') },
              },
              changeWeekSunThu: {
                text: formatMessage({ id: 'calendar.week.sunThu' }),
                click: () => { changeStartOfWeek('sun-thu') },
              },
              changeViewMonth: {
                text: formatMessage({ id: 'calendar.monthView' }),
                click: () => { changeClanedarView('dayGridMonth') },
              },
              changeViewList: {
                text: formatMessage({ id: 'calendar.listView' }),
                click: () => { changeClanedarView('listMonth') },
              },
            }}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'changeWeekMonFri,changeWeekSunThu changeViewMonth,changeViewList',
            }}
            events={[
              ...calendarEvents,
              ...calendarHolidaysEvents,
              ...blackoutPeirodEvents,
              ...recurringBlackoutPeirods,
              ...recurringBlackoutPeirodsEvents,
            ]}
            eventMouseEnter={handleEventPositioned}
          />
          <ReactTooltip html={true} className="on-top" />
        </div>
      }
    </div>
  )
}

export default CalendarTabComponent